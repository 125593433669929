<script>
import { GlobeIcon } from 'vue-feather-icons';

export default {
  components: {
    GlobeIcon
  },
  data() {
    return {
      languages: [
        { code: 'en', name: 'English' },
        { code: 'es', name: 'Español' },
      ]
    };
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem('preferredLanguage', lang);
    },
    loadPreferredLanguage() {
      const savedLanguage = localStorage.getItem('preferredLanguage');

      if (savedLanguage) {
        // Asigna el idioma guardado en localStorage
        this.$i18n.locale = savedLanguage;
      } else {
        const browserLanguage = navigator.language || navigator.userLanguage;
        const defaultLanguage = browserLanguage.split('-')[0];
        this.$i18n.locale = defaultLanguage;
      }
    },
  },
  mounted() {
    this.loadPreferredLanguage();
  }
};
</script>

<style>
.header {
  display: block;
  height: 3.5rem;
}

.navbar .navbar__logo img {
  max-width: 200px;
  height: 1.5rem;
  object-fit: contain;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: -1px -6px 20px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.navbar__logo {
  display: inline-block;
  padding: 10px 20px;
}

.navbar__language-switch {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.navbar__language-button {
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  margin-left: 15px;
}

.navbar__icon {
  margin-right: 8px;
}
</style>

<template>
  <header class="header">
    <div class="navbar" id="topnav">
      <span class="navbar__logo">
        <img src="/images/duppla_negro_header.png" alt="" />
      </span>
      <div class="navbar__language-switch">
        <button class="navbar__language-button" v-if="this.$i18n.locale === 'en'" @click="changeLanguage('es')">
          <globe-icon class="navbar__icon fea icon-sm icons"></globe-icon> Español
        </button>
        <button class="navbar__language-button" v-if="this.$i18n.locale === 'es'" @click="changeLanguage('en')">
          <globe-icon class="navbar__icon fea icon-sm icons"></globe-icon> English
        </button>
      </div>
    </div>
  </header>
</template>
