<script>
import {
    ArrowUpIcon,
    ArrowRightIcon
} from 'vue-feather-icons';

import Navbar from "@/components/navbar";
import Footer from "@/components/footer";
/**
 * Page-terms component
 */
export default {
    data() {
        return {}
    },
    components: {
        Navbar,
        Footer,
        ArrowUpIcon,
        ArrowRightIcon
    }
}
</script>

<template>
<div class="bg-light">
    <Navbar :isIcons="false" :navOptions="false" :signupOptions="false" :corporateOptions="false"/>

    <!-- Hero Start -->
    <section class="bg-half d-table w-100" style="padding-top: 50px; padding-bottom: 50px;">
        <div class="container">
            <div class="row justify-content-center mt-5">
                <div class="col-lg-12 text-center">
                    <div class="page-next-level">
                        <h4 class="title"> Términos y Condiciones del Servicio de Checkup Digital</h4>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Shape Start -->
    <div class="position-relative">
        <div class="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!--Shape End-->

    <!-- Start Terms & Conditions -->
    <section class="section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="card shadow border-0 rounded">
                        <div class="card-body">
                            <p class="text-muted">
                                Hub de Pacientes DPPL, S.A.P.I. de C.V. (en adelante <b class="text-primary ">"Duppla"</b>), con domicilio en: Avenida Lázaro Cárdenas 2400, piso 5, local E1, Col. Del Valle Oriente, San Pedro Garza García, Nuevo León, pone a disposición de los usuarios de la página web www.duppla.doctor, ofrece a sus pacientes y a personas mayores de 18 (dieciocho) años, o menores de edad por conducto de sus tutores legales, el servicio de Checkup Digital (el “Servicio”), a través de la página web: <a href="https://www.duppla.doctor/" target="_blank">https://www.duppla.doctor/</a> y páginas, subpáginas, ventanas y pop-ups desplegadas en el mismo sitio y que pertenecen a dicha página web, así como cualquier aplicación móvil de <b class="text-primary">Duppla</b>, los cuales serán prestados de conformidad con lo establecido en estos Términos y Condiciones:
                                <br/><br/>
                                Se da por entendido que usted comprende y acepta absoluta y expresamente los siguientes “Términos y Condiciones de Checkup Digital”, con la finalidad de que se le puedan prestar los Servicios que se ofrecen en el Checkup Digital, por lo cual usted declara expresamente su aceptación a través de medios electrónicos, de conformidad con lo dispuesto por los artículos 1803 del Código Civil Federal y 93 del Código de Comercio en vigor, por lo que es importante que analice lo detallado en este documento. En caso de no estar de acuerdo completamente con lo aquí expresado, <b class="text-primary">Duppla</b> no podrá prestar los Servicios. En caso de que usted acceda, y utilice la página web o cualquier aplicación móvil de Duppla, se considerará como una absoluta y expresa aceptación de los Términos y Condiciones de uso aquí estipulados.
                            </p>

                            <h5 class="card-title">Artículo 1. Uso del sitio.</h5>
                            <p class="text-muted">
                                <b class="text-primary">1.1. Generalidades.</b> El presente documento establece los “Términos y Condiciones” a los cuales se sujeta Usted como usuario de la página web o cualquier aplicación móvil de Duppla, así como para llevar a cabo la prestación de los Servicios. Adicionalmente, y para poder utilizar los Servicios, el usuario necesita ingresar datos personales, incluyendo datos personales sensibles (correo electrónico, edad, sexo de nacimiento, información médica tales como: antecedentes médicos, síntomas, malestares, entre otros), los cuales serán tratados de conformidad con el Aviso de Privacidad de Duppla, y lo dispuesto por la Cláusula 4.2. de estos Términos y condiciones.
                            </p>
                            <p class="text-muted">
                                <b class="text-primary">1.2. Propiedad Intelectual de Duppla:</b> La marca “Duppla”, los logotipos y diseños relacionados con la misma, se encuentran legalmente registrados o en proceso de registro ante el Instituto Mexicano de la Propiedad Industrial (“IMPI”). Por lo anterior, no pueden ser usados con relación a otros bienes, productos o servicios en virtud de que los derechos y su uso son exclusivos de Hub de Pacientes DPPL, S.A.P.I. de C.V. El contenido de este sitio está sujeto a derechos de propiedad industrial e intelectual, así como de derechos de autor propiedad de Hub de Pacientes DPPL, S.A.P.I. de C.V. Todo lo contenido en este sitio: el texto, las gráficas, logos, íconos, imágenes, audio clips, gráficos personalizados, fotografías originales, datos, imágenes, música, fragmentos de audio y video, fuentes, títulos, íconos de botones, logos, diseños, software y cualquier otro contenido que pueda considerarse como un derecho de propiedad industrial y/o intelectual, son propiedad o han sido debidamente licenciados por sus propietarios o licenciatarios a favor de Hub de Pacientes DPPL, S.A.P.I. de C.V., y se encuentran protegidos de conformidad con la legislación vigente aplicable en México. 
                                <br/><br/>
                                Duppla le otorga a usted, acceso para usar la página web o cualquier aplicación móvil de Duppla, y los contenidos de la misma en dispositivos móviles. Usted acepta no interrumpir ni intentar interrumpir el funcionamiento de este sitio.
                                <br/><br/>
                                El presente sitio, su contenido y el software (incluyendo cualquier aplicación móvil) sólo pueden ser usados para la prestación de los Servicios de Checkup Digital de Duppla. Cualquier otro uso incluyendo la reproducción, modificación, distribución, transmisión, exhibición o ejecución del contenido del sitio se encuentra estrictamente prohibido.
                                <br/><br/>
                                Queda terminantemente prohibido copiar, publicar, transmitir, retransmitir, transmitir por internet u otros medios análogos, modificar, eliminar, aumentar, distribuir, descargar, almacenar, reproducir, otorgar en licencia, adaptar, crear trabajos derivados de este contenido o publicar este contenido o partes de este contenido de cualquier modo en computadoras u otras tecnologías que existan en la actualidad o que se desarrollen de aquí en adelante. A través de la aceptación de los presentes Términos y Condiciones, usted acepta no reproducir, duplicar, copiar, vender, revender, descompilar, desarmar ni explotar para fines comerciales ninguna parte de este sitio, su uso y acceso, ni recopilar información de visitantes o de usuarios registrados en este sitio. Ningún usuario podrá subir, publicar, reproducir ni distribuir de ningún modo contenido protegido por derechos de autor u otros derechos de propiedad, sin haber obtenido un permiso expreso de Duppla como propietario del derecho de autor o de otro derecho de propiedad correspondiente. El uso de la página web o cualquier aplicación móvil de Duppla, no le confiere títulos, derechos ni intereses. Usted reconoce que los derechos de Duppla son válidos y están protegidos en todas las formas, los medios y las tecnologías que existan en la actualidad o que se desarrollen de aquí en adelante. Los avisos de marca registrada o de derechos de autor no pueden eliminarse ni alterarse de ningún modo. 

                            </p>
                            <p class="text-muted">
                                <b class="text-primary">1.3. Enlaces a otros sitios:</b> La página web o cualquier aplicación móvil de Duppla puede contener enlaces a sitios que pertenecen a terceros independientes o que son administrados por ellos. Estos enlaces se ofrecen únicamente para conveniencia y referencia del usuario. Duppla no controla dichos sitios y, por lo tanto, no es responsable del contenido publicado en ellos. Duppla se reserva el derecho de cancelar y/o deshabilitar dichos enlaces en cualquier momento.
                            </p>
                            <p class="text-muted">
                                <b class="text-primary">1.4. Ausencia de obligaciones respecto de la página web o cualquier aplicación móvil de Duppla:</b> Duppla tendrá derecho en cualquier momento y sin previo aviso, a restringir, cancelar o deshabilitar cualquier Servicio, eliminar, remover o editar el contenido del sitio, a su entera discreción. Duppla se reserva el derecho de corregir cualquier error u omisión de cualquier información en el sitio. Duppla se compromete a que, en caso de resultar cualquier falla o falta tecnológica propia de la página web o cualquier aplicación móvil de Duppla, que impida la prestación de los Servicios la resolverá a la brevedad. El software, enlaces y demás recursos en el sitio son ofrecidos por Duppla en el estado en el que se encuentran y sin garantizar la calidad, estado, funcionamiento o compatibilidad de los mismos con cualquier dispositivo en cual se utilice o ejecute; por lo que no asume ninguna responsabilidad con relación a cualquier daño y/o perjuicio causado.
                                <br/><br/>
                                Duppla no garantiza que la página web o cualquier aplicación móvil de Duppla satisfaga los requerimientos del usuario o que los Servicios que en ella se ofrecen no sufran interrupciones, sean seguros o estén exentos de errores. Duppla se libera de cualquier responsabilidad y condiciones, respecto a: (a) la ausencia de virus, errores, desactivadores o cualquier otro material contaminante o con funciones destructivas en la información o programas disponibles en o a través del sitio, y (b) Duppla, por sí misma o través de sus proveedores podrán actualizar el contenido la página web o cualquier aplicación móvil constantemente, por lo que se solicita al usuario tomar en cuenta que alguna información publicitada o contenida en o a través del mismo, pueden haber quedado obsoletas y/o contener imprecisiones o errores tipográficos u ortográficos.
                                <br/><br/>
                                En la medida más amplia permitida por las leyes aplicables, Duppla no será responsable, en ningún caso, por daños directos, especiales, incidentales, indirectos, o consecuenciales que en cualquier forma se deriven o se relacionen con: (c) el uso o ejecución del sitio o la falta de disponibilidad de éste, y (d) cualquier otro aspecto o característica de la información contenida o publicada en la página web o cualquier aplicación móvil de Duppla, a través de las ligas que eventualmente se incluyan en ellas.

                            </p>
                            <p class="text-muted">
                                <b class="text-primary">1.5. Suplantación de Identidad y Spam:</b> En la medida en que lo permita Duppla a través del sitio, el usuario no podrá enviar correos en cadena, masivo de correos o constituya cualquier otro tipo de "spam". No está permitido el uso de direcciones de correo falsas, ni suplantar la identidad de otra persona o entidad, ni falsear de cualquier otro modo el origen de cualquier contenido.
                            </p>

                            <h5 class="card-title">Artículo 2. Registro.</h5>
                            <p class="text-muted">
                                <b class="text-primary">2.1. Declaración de Veracidad de Datos de los usuarios:</b> Los usuarios que para poder hacer uso de los Servicios de la página web o cualquier aplicación móvil de Duppla, han decidido libremente llenar y aceptar la forma de registro de información de datos personales incluyendo los de arater sensible, aceptan en su carácter de usuario los presentes Términos y Condiciones, así como el Aviso de Privacidad Integral, que puede ser consultados en <a href="/terminos/" target="_blank">https://www.duppla.doctor/terminos/</a>  y <a href="/privacidad/" target="_blank">https://www.duppla.doctor/privacidad/</a>.
                                <br/><br/>
                                Los usuarios se comprometen y obligan al uso adecuado y responsable de la página web o cualquier aplicación móvil de Duppla. Asimismo, serán responsables de que todos los datos e información transmitida a Duppla para la prestación de los Servicios sean correctos y veraces.
                            </p>
                            <p class="text-muted">
                                <b class="text-primary">2.2. Información para Registro:</b> El usuario reconoce que el acceso a la página web o cualquier aplicación móvil de Duppla a través de su correo electrónico y otros datos personales, se considerará como una firma electrónica y que cualquier mensaje de datos o comunicación realizada a través de esos medios, será atribuible a el, como usuario.
                            </p>
                            <p class="text-muted">
                                <b class="text-primary">2.3 Mensajes de Datos:</b> El usuario acuerda y conviene que la información que envíe a Duppla para la prestación de los Servicios a través de mensajes de datos mediante la página web o cualquier aplicación móvil de Duppla, serán vinculantes para el usuario como una forma de manifestación expresa y por escrito de su voluntad y podrá ser conservada por Duppla. El usuario autoriza que cualquier mensaje de datos o de cualquier naturaleza, ha sido emitido por el usuario cuando provengan de cualquier dirección electrónica o número telefónico que haya registrado con Duppla. El usuario reconoce que cualquier mensaje enviado por Duppla por cualquiera de dichos medios, se tendrá por recibido como si fuera de forma escrita. El usuario acepta que todos los contratos y/o convenios, avisos y otras notificaciones y comunicaciones que Duppla o el usuario realicen por medios electrónicos o mediante el sitio, satisfacen cualquier requisito legal de forma escrita; lo anterior de conformidad con el artículo 89 del Código de Comercio.
                            </p>

                            <h5 class="card-title">Artículo 3. De los Servicios al usuario.</h5>
                            <p class="text-muted">
                                <b class="text-primary">3.1.</b> Con el objeto de que se le puedan prestar los Servicios al usuario, este deberá ser mayor de 18 años, en cuyo caso Duppla en cualquier momento estará facultado para solicitar que acredite dicha información. En el caso de menores de edad, deberá ser solicitado por los padres o tutores, y Duppla en todo momento tendrá derecho a solicitar algún documento que acredite la patria potestad. 
                            </p>
                            <p class="text-muted">
                                <b class="text-primary">3.2.</b> El usuario reconoce y acepta que el objeto de la página web o cualquier aplicación móvil de Duppla deberá es determinar las probabilidades de que el usuario sin síntomas ni preexistencia de cáncer, desarrolle algún tipo de Cáncer en los próximos 5 (cinco) años, con el objeto de reducir tal riesgo, razón por la cual requiere recabar a través de un cuestionario cierta información confidencial incluyendo datos sensibles del usuario, mismos que será tratados de conformidad con el Aviso de Privacidad de Duppla, y lo dispuesto por la Cláusula 4.2. estos Términos y Condiciones.
                            </p>
                            <p class="text-muted">
                                <b class="text-primary">3.3.</b> En caso de que el usuario reporte algunos síntomas, Duppla sugerirá a este que lo consulte con un especialista, o se ponga en contacto con Duppla a través del Onco.Chat, a fin de que reciba atención especializada y en su caso, se encuentre el mejor tratamiento para el usuario.
                            </p>
                            <p class="text-muted">
                                <b class="text-primary">3.4.</b> El usuario reconoce y acepta que derivado de los resultados arrojados por el cuestionario formulado en la página web o cualquier aplicación móvil de Duppla, ésta podrá ofrecer servicios adicionales, tales como un plan de tamizaje (exámenes preventivos) atendiendo a las recomendaciones médicas mas recientes.
                            </p>
                            <p class="text-muted">
                                <b class="text-primary">3.5.</b> El usuario reconoce y acepta que Duppla podrá enviarle recomendaciones más específicas dependiendo de su caso vía WhatsApp, por lo que deberá de dar su consentimiento para recibir las mismas. Duppla enviará dichas recomendaciones al número de teléfono con Lada que haya ingresado el usuario en la página web o cualquier aplicación móvil de Duppla, por lo que, en caso de que el usuario haya ingresado el número incorrectamente no será responsabilidad de Duppla, y se tendrá por cumplida la obligación enviando la información al número registrado.
                            </p>
                            <p class="text-muted">
                                <b class="text-primary">3.6.</b> El usuario es responsable de la veracidad de la información facilitada a Duppla para la prestación de los Servicios, por lo que Duppla emite los resultados basándose completamente en las respuestas proporcionadas por el usuario. El documento que Duppla emita para conocer los resultados, tiene un carácter meramente informativo, sin que se considere diagnóstico médico, ni lleve al paciente a abandonar o modificar otros tratamientos médicos, sin previamente consultar con un profesional de la salud o al OncoChat de Duppla.
                            </p>
                            <p class="text-muted">
                                <b class="text-primary">3.7.</b> Los Servicios de Duppla serán prestados a distancia a través de tecnologías de la información, tratando los datos personales (incluyendo los sensibles) para alcanzar las finalidades pretendidas, por lo que Duppla mantendrá implementadas en todo momento medidas de seguridad adecuadas para garantizar la integridad y confidencialidad de los datos médicos y clínicos de los usuarios.
                            </p>

                            <h5 class="card-title">Artículo 4. Misceláneos</h5>
                            <p class="text-muted">
                                <b class="text-primary">4.1. Indemnización:</b> El usuario reconoce que Duppla no asume ninguna obligación o responsabilidad que no se encuentre específicamente establecida en los presentes Términos y Condiciones. El usuario en este acto se obliga a indemnizar a Duppla, a sus empleados, funcionarios, consejeros, comisionistas y afiliadas por cualquier daño, perjuicio, pérdida o menoscabo que sufran con motivo del incumplimiento de sus obligaciones conforme a los presentes Términos y Condiciones (incluyendo sin limitación cualquier gasto u honorario judicial, así como de abogados en los que incurra Duppla) obligándose a sacar a dichas personas en paz y a salvo de todos y cualquier procedimiento, juicio, investigación, averiguación y/o procesos que se sigan en su contra con relación a lo anterior.
                            </p>
                            <p class="text-muted">
                                <b class="text-primary">4.2. Datos Personales:</b> Usted puede consultar nuestro Aviso de Privacidad Integral en nuestro sitio <a href="/privacidad/" target="_blank">https://www.duppla.doctor/privacidad/</a>  y ejercer los derechos de acceso, rectificación, cancelación y oposición (ARCO) por solicitud por escrito a la direión de correo electrónico: <a href="mailto:privacidad@duppla.doctor" target="_blank">privacidad@duppla.doctor</a>. 
                            </p>
                            <p class="text-muted">
                                <b class="text-primary">4.3. Modificaciones:</b> Duppla podrá en cualquier momento y cuando lo considere conveniente, sin necesidad de avisar al usuario, a realizar correcciones, adiciones, mejoras o modificaciones al contenido, presentación, información, servicios, áreas, bases de datos y demás elementos del sitio, sin que ello de lugar ni derecho a ninguna reclamación o indemnización por parte del usuario y, ni que esto implique reconocimiento de responsabilidad alguna a favor de Duppla. Duppla se reserva el derecho de modificar los presentes Términos y Condiciones en cualquier momento y sin previo aviso, en el entendido de que dichas modificaciones no surtirán efectos retroactivamente y que únicamente serán aplicables a visitas al sitio y operaciones realizadas con posterioridad a dichas modificaciones. El usuario en este acto conviene que los Términos y Condiciones únicamente son aplicables para las operaciones que realice con Duppla de tiempo en tiempo y conviene en sujetarse y quedar obligado por cualquier modificación a los presentes Términos y Condiciones para cualquier Servicio que solicite con posterioridad a dichas modificaciones.
                            </p>
                            <p class="text-muted">
                                <b class="text-primary">4.4. Terminación:</b> Duppla se reserva el derecho, a su exclusiva discreción y sin necesidad de avisar al usuario, para descontinuar o dejar de publicar definitivamente en el sitio sin responsabilidad para Duppla, sus afiliadas, subsidiarias o proveedores.
                            </p>
                            <p class="text-muted">
                                <b class="text-primary">4.5. Legislación y Jurisdicción:</b> Los presentes Términos y Condiciones se regirán por la legislación aplicable vigente en los Estados Unidos Mexicanos. Las operaciones que se celebren entre Duppla y el Cliente estarán sujetas a la Ley Federal de Protección al Consumidor, la Ley General de Salud, su reglamento, y demás normatividad aplicable. El usuario en este acto acepta que cualquier controversia con relación a los presentes Términos y Condiciones, será resuelta ante las autoridades y los tribunales con sede en la Ciudad de México, renunciando expresamente a cualquier otra jurisdicción que le pudiera corresponder por razón de su domicilio presente o futuro, por cualquier otra causa.
                            </p>
                            <br/>
                            <p class="text-muted">
                                Actualización de los presentes Términos y Condiciones a marzo de 2023.
                            </p>

                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- End Terms & Conditions -->
    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <!--Switcher /-->
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
</div>
</template>
